import React from "react";
import { Box, Stack } from "@mui/material";
import './Global.css';
import Navbar from "./dashboard/Navbar";
import { Outlet } from "react-router-dom";

export default function Dashboard() {
  return (
    <Box>
      <Navbar />
      <Box className="logincontainer">
        <Stack alignItems="center" spacing={4}>
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
}
