import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import '../Global.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Stack, Typography, TextField, Paper, Select, InputLabel, MenuItem, FormControl, } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { db } from '../../firebase/initfirebase'
import { collection, query, where, limit, limitToLast, orderBy, startAfter, endBefore } from "firebase/firestore"
import { tableheader, channellist, orderstatuslist, paystatuslist, permission, filterobj, dateselection } from "../listsnobjects"
import { dateconvert, dataupdate, datafetching } from "../../firebase/managedb"

export default function Orderlist({prop}) {
  let navigate = useNavigate()
  const {type, lang, username} = prop

  //Hook for fetched database
  const [display_orders, setDisplay_orders] = useState();

  //Hook for pagination control
  const [page, setPage] = useState(0)
  const [pageaction, setPageaction] = useState()
  const [pagebutton, setPagebutton] = useState({
    prev: false,
    next: false,
  })
  const [batchrec, setBatchrec] = useState({
    first: '',
    last: '',
  })

  /*
  //Hook for Filter
  const [dbfilter, setDbfilter] = useState(filterobj)
  const [dateselected, setDateselected] = useState("order")
  const [daterange, setDaterange] = useState()

  useEffect(()=>{
    console.log(dbfilter)
    console.log(dateselected)
  }, [dbfilter, dateselected])
  */

  //Hook for database reading control
  const [dbreload, setDbreload] = useState(true)

  //Read the order collection from database once only in initialisation
  useEffect(()=>{
    let reading =true
    if (dbreload) {
      const readdb = async () => {
        
        //Read the database orders
        let role
        if (permission["orderlist"]["showdeliveredonly"][type]) {
          //Warehouse
          role = where("orderstatus", "in", ["packing", "packed", "delivery"])
        } else if ((type === "admin")||(type === "poweruser")) {
          //Admin
          role = ""
        } else {
          //Sales
          let sales = username.split(' ')
          role = where("sales", "==", sales[0])
        }

        let q
        //For Page 1 only
        if (page===0) {
           q = query(
            collection(db, "orders"),
            role && role,
            orderBy("orderdate", "desc"),
            limit(10)
          ) 
        } else {
          //For other pages
          q = query(
            collection(db, "orders"),
            role && role,
            orderBy("orderdate", "desc"),
            pageaction===1 ? startAfter(batchrec.last) : endBefore(batchrec.first), //Next is clicked / Previous is clicked
            pageaction===1 ? limit(10) : limitToLast(10), //Next is clicked / Previous is clicked
          )
        }

        //Process the data fetched
        const dataprocess = (querySnapshot) => {
          let templist = []
          querySnapshot.forEach((order) => {
            //Expiry check and update
            const expiry = (duedate) => {
              let exp = false
              let today = (Math.trunc(new Date()/86400000))*86400000 //Today at 0:00 am
              //console.log(`Today is ${today}. Due is ${duedate.toDate()}`)
              if (duedate.toDate() < today) {
                exp = true
                //console.log("Expired!")
              }
  
              //Indicate Overdue if it is expired
              if ((order.data().paymentstatus==="unsettled") && (exp)) {
                //Update the payment status in the database
                const updateobj = { paymentstatus: "overdue" }
                dataupdate("orders", order.id, updateobj).then((res)=>{
                  //console.log(res)
                }, (err) => {
                  console.log(err)
                })
                return "overdue"
              } else {
                return order.data().paymentstatus
              }
            }
  
            //Put the data into the hook
            let orderinfo = { 
              orderid: order.id, 
              orderdate: order.data().orderdate.toDate(),
              channels: order.data().channels,
              name: order.data().name,
              contact: order.data().contact,
              orderstatus: order.data().orderstatus,
              paymentstatus: expiry(order.data().paymentduedate),
              paymentduedate: order.data().paymentduedate.toDate(),
              paiddate: order.data().paiddate ? order.data().paiddate.toDate() : "-",
            }
            templist.push(orderinfo)
          })
          if (reading) {
            setDisplay_orders(templist);

            //Pagination
            setBatchrec({ //Record the first and last record
              first: querySnapshot.docs[0],
              last: querySnapshot.docs[querySnapshot.docs.length-1],
            })
            setPagebutton({ //Pagination button Enabled / Disabled
              prev: page > 0 ? true : false,
              next: querySnapshot.docs.length < 10 ? false : true,
            })
            setDbreload (false)
          }
        }

        //Readeing the database
        const readorderdb = async () => {
          const querySnapshot = await datafetching(q)
          if (!querySnapshot.empty) {
            //Data received
            dataprocess(querySnapshot)
          } else { 
            //No data received
            setPagebutton({...pagebutton, next: false,})
            if (page===0) {
              let templist = []
              let obj = {
                orderid: "", 
              };
              templist.push(obj)
              setDisplay_orders(templist)
            }
            setDbreload (false)
          }
        }
        readorderdb();
      }
      readdb()
    }
    return () => { reading = false }
    // eslint-disable-next-line
  }, [dbreload])

  /*
const handledatefilterchange = (event) => {
  console.log(event.target.value)
  setDateselected(event.target.value)
  setDbfilter({
    ...dbfilter,
    orderdatefrom: '',
    orderdateto: '',
    paiddatefrom: '',
    paiddateto: '',
    deliverydatefrom: '',
    deliverydateto: '',
  })
}
  */

/*
const handledatechange = (prop) => (event) => {
  let attr = dateselected+"date"+prop
  console.log (attr)
  console.log (event)
  setDbfilter({...dbfilter, [attr]: event})
}
*/

 //Display table
  const handleChangePage = (paginationbutton) => (event) => {
    let newPage = page + paginationbutton
    if (newPage < 0) {newPage = 0}
    setPage(newPage)
    setPageaction(paginationbutton)
    setDbreload(true)
    setDisplay_orders()
  };

  const handleClick = (orderid) => (event) => {
    navigate(`/orderlist/${orderid}`)
  }

  return (
    <Box className="orderlistcontainer">

    {
      /*
      <Paper variant="outlined" className="paperstyle">
        <Stack justifyContent="flex-start" spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography variant="h2">
            { lang==="en" ? "Filter" : "篩選項目" }
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" spacing={4}>
            <FormControl className="sfield">
              <InputLabel id="dateselection-label">Date Filter by</InputLabel>
              <Select
                labelId="dateselection-label"
                id="Date selection"
                value={dateselected}
                label="Date Filter by"
                onChange={handledatefilterchange}
              >
                {dateselection.map((dateoption, index) => (
                  <MenuItem key={index} value={dateoption.id}>
                    {dateoption.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DateRangePicker 
                  disableFuture 
                  localeText={{ start: 'From', end: 'To' }}
                  value={daterange}
                  onChange={(newValue)=>console.log(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Paper>
      
      */
    }

{
  /*

            <Stack direction="row" justifyContent="flex-start" spacing={2}>
              <Typography variant="body1" sx={{lineHeight: 2.8,}}>
                { lang==="en" ? "From" : "由" }
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  value={dbfilter[dateselected+"datefrom"]}
                  onChange={ handledatechange("from") }
                  disableFuture
                  renderInput={(params) => (
                    <TextField {...params} className={classes.mfield} />
                  )} //Display the input field
                  //Display the calendar
                  renderDay={(days, selected, pickersDayProps) => (
                    <PickersDay
                      //Display DAY in the calendar
                      {...pickersDayProps}
                      sx={{ color: "#000000" }}
                    />
                  )}
                  okText={<span style={{ color: "#000000" }}>OK</span>}
                  cancelText={<span style={{ color: "#000000" }}>CANCEL</span>}
                />
              </LocalizationProvider>


            </Stack>
            <Stack direction="row" justifyContent="flex-start" spacing={2}>
              <Typography variant="body1" sx={{lineHeight: 2.8,}}>
                { lang==="en" ? "To" : "至" }
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  value={dbfilter[dateselected+"dateto"]}
                  onChange={ handledatechange("to") }
                  disableFuture
                  //Display the input field
                  renderInput={(params) => (
                    <TextField {...params} className={classes.mfield} />
                  )}
                  //Display the calendar
                  renderDay={(days, selected, pickersDayProps) => (
                    <PickersDay
                      //Display DAY in the calendar
                      {...pickersDayProps}
                      sx={{ color: "#000000" }}
                    />
                  )}
                  okText={<span style={{ color: "#000000" }}>OK</span>}
                  cancelText={<span style={{ color: "#000000" }}>CANCEL</span>}
                />
              </LocalizationProvider>
            </Stack>
  */
}
      {/* Data Display Table */}

      <Stack justifyContent="center" spacing={4} sx={{mt: 0,}}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableheader.map((tableheader, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{ minWidth: index===2 ? 280: 100, color: "#000000" }}
                    //sortDirection={ sorting.col === tableheader.id ? sorting.order : false }
                  >
                    <TableSortLabel
                      //active={sorting.col === tableheader.id}
                      //direction={sorting.col === tableheader.id ? sorting.order : "asc"}
                      //onClick={sortingHandler(tableheader.id)}
                    >
                      { lang==="en" ? tableheader.display : tableheader.cdisplay }
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {display_orders ? (
                display_orders[0].orderid ?
                (
                display_orders
                  .map((order) => {
                    return (
                      <TableRow
                        hover
                        key={order.orderid}
                        onClick={handleClick(order.orderid)}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {dateconvert(order.orderdate)}
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {
                            lang==="en" ?
                            channellist.filter((channel)=>channel.id===order.channels)[0].display
                            :
                            channellist.filter((channel)=>channel.id===order.channels)[0].cdisplay
                          }
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {order.name}
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {order.contact}
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {
                            lang==="en" ?
                            orderstatuslist.filter((orderstatus)=>orderstatus.id===order.orderstatus)[0].display
                            :
                            orderstatuslist.filter((orderstatus)=>orderstatus.id===order.orderstatus)[0].cdisplay
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color:
                              order.paymentstatus === "overdue"
                                ? "#FF0000"
                                : "#000000",
                          }}
                        >
                          {
                            lang==="en" ?
                            paystatuslist.filter((paystatus)=>paystatus.id===order.paymentstatus)[0].display
                            :
                            paystatuslist.filter((paystatus)=>paystatus.id===order.paymentstatus)[0].cdisplay
                          }
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {order.paymentstatus === "paid"
                            ? "-"
                            : dateconvert(order.paymentduedate)}
                        </TableCell>
                        <TableCell align="center" style={{ color: "#000000" }}>
                          {order.paiddate==="-" ? "-" : dateconvert(order.paiddate)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ color: "#000000" }}
                      colSpan={8}
                    >
                      No Order Found!
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ color: "#000000" }}
                    colSpan={8}
                  >
                    <CircularProgress color="inherit" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {
        display_orders && (
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={4}>
          <Box display={pagebutton.prev||page>0 ? "block" : "none"} onClick={handleChangePage(-1)} sx={{ px: 3, py: 3, justifyContent: "center", "&:hover": { cursor: "pointer" } }}>
            Prev
          </Box>
          <Box  sx={{px: 3, py: 3, justifyContent: "center"}} >
            Page {page + 1}
          </Box>
          <Box display={pagebutton.next ? "block" : "none"} onClick={handleChangePage(1)} sx={{ px: 3, py: 3, justifyContent: "center", "&:hover": { cursor: "pointer" } }}>
            Next
          </Box>
        </Stack>
      )}
    </Box>
  );
}
