import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import '../Global.css'
import {
  Paper,
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Divider,
  TextField,
  Stack,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Slide,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { db, storage } from "../../firebase/initfirebase";
import { ref, uploadString } from "firebase/storage";
import { datafetching, dataupdate, itemlistsorting } from "../../firebase/managedb";
import {
  collection,
  query,
  orderBy,
  addDoc,
  where,
} from "firebase/firestore";
import {
  permission,
  orderobj,
  customerobj,
  saleslist,
  channellist,
  orderstatuslist,
  deliverylist,
  paymentlist,
} from "../listsnobjects";
import Filehandle from './Filehandle';

const StyledDay = styled(PickersDay)(({ theme }) => ({
  color: '#000000',
}));

export default function Orderinput({prop}) {
  const {type, lang} = prop;

  const [customerlist, setCustomerlist] = useState([]);
  const [customerlistopen, setCustomerlistopen] = useState(false); //The Customer Selection List
  const [itemlist, setItemlist] = useState([]);
  const [itemlistopen, setItemlistopen] = useState([false]); //The Item Selection List

  //Read the customer list and item list from database and put it in hook, run once only
  useEffect(() => {
    //Read the customer database
    let customer_reading = true;
    if (customerlist.length===0) {
      const readcustomerdb = async () => {
        let templist = [];
        const q = query(
          collection(db, "customers"),
          orderBy("type"),
          orderBy("name")
        );

        const dataprocess = (querySnapshot) => {
          querySnapshot.forEach((customer) => {
            let customerinfo = {
              id: customer.id,
              name: customer.data().name,
              list_displayname: customer.data().type === 'User' ? customer.data().contact : customer.data().name,
              type: customer.data().type,
              contact: customer.data().contact,
              phone: customer.data().phone,
              email: customer.data().email,
              address: customer.data().address,
              address2: customer.data().address2 ? customer.data().address2 : "",
              payment: customer.data().payment,
              remarks: customer.data().remarks ? customer.data().remarks : "",
            };
            templist.push(customerinfo);
          });
          if (customer_reading) {
            let addnew = {
              id: "new",
              list_displayname: " + New Customer",
              type: "",
            }
            templist
            .sort((a, b) => (a.list_displayname.toUpperCase() > b.list_displayname.toUpperCase() ? -1 : 1))
            .sort((a, b) => (a.type > b.type ? 1 : -1))
            .splice(0,0,{...addnew})
            //console.log(templist)
            setCustomerlist(templist);
          }
        }

        const fetching = async () => {
          const querySnapshot = await datafetching(q)
          if (!querySnapshot.empty) { dataprocess(querySnapshot) }
        }
        fetching();
      };
      readcustomerdb();
    }

    //Read the item database
    let item_reading = true;
    if (itemlist.length===0) {
      const readitemdb = async () => {
        let templist = [];
        const q = query(
          collection(db, "items"),
          where('brand', 'in', permission.orderinput.specificitemlist[type]),
        );
        const dataprocess = (querySnapshot) => {
          querySnapshot.forEach((item) => {
            let brandcheck = permission.orderinput.specificitemlist[type].findIndex(brand=>brand===item.data().brand)
            if (brandcheck>-1) {
              let iteminfo = {
                itemid: item.id,
                name: item.data().name,
                description: item.data().description,
                brand: item.data().brand,
                dsp: item.data().dsp,
                rsp: item.data().rsp,
                target: item.data().target,
                category: item.data().category,
                display_group: item.data().brand === "g2h" ? `Other Charge - ${item.data().category.toUpperCase()}` : `${item.data().brand.toUpperCase()} - ${item.data().category.toUpperCase()}`,
              };
              templist.push(iteminfo);
            }
          });
          if (item_reading) {
            let sortedlist = itemlistsorting(templist);
            //console.log(sortedlist)
            setItemlist(sortedlist);
          }
        }

        const fetching = async () => {
          const querySnapshot = await datafetching(q)
          if (!querySnapshot.empty) { dataprocess(querySnapshot) }
        }
        fetching();
      };
      readitemdb();
    }

    return () => {
      customer_reading = false;
      item_reading = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Hook for submission button enable
  const [submitbtn, setSubmitbtn] = useState({
    order: false,
    customer: false,
  });

  //Hook for storing the orders data, and validate the order part
  const [orders, setOrders] = useState(orderobj);
  useEffect(() => {
    //console.log(orders);
    if (!orders.orderdate) {
      //Initialization, set the order date
      //console.log("Initialize the order hook now");
      let today = new Date();
      setOrders({
        ...orderobj,
        orderdate: today,
        paymentduedate: today,
        order: [{ itemid: "", name: "", qty: "", price: "", description: "" }],
      });
    } else {
      //Validation
      let noerror = true;
      if (!orders.channels) {
        noerror = false;
        //console.log(`${orders.channels}, Channels issue`);
      } else if (orders.channels === "sales" && !orders.sales) {
        noerror = false;
        //console.log(`${orders.sales}, Sales issue`);
      } else {
        for (let i = 0; i < orders.order.length; i++) {
          if (!orders.order[i].itemid) {
            noerror = false;
            //console.log(`${orders.order[i].itemid}, ItemID issue`);
          } else if ((!orders.order[i].qty) || (isNaN(orders.order[i].qty))) {
            noerror = false;
            //console.log(`${orders.order[i].qty}, quantity issue`);
          } else if (isNaN(orders.order[i].price)) {
            noerror = false;
            //console.log(`${orders.order[i].price}, price issue`);
          }
        }
      }
      if (noerror !== submitbtn.order) {
        setSubmitbtn({ ...submitbtn, order: noerror });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  //Hook for storing the current customer data, and validate the customer part
  const [currentcustomer, setCurrentcustomer] = useState({...customerobj, list_displayname: "",});
  useEffect(() => {
    //console.log(currentcustomer);
    //Validation
    let noerror = true;
    if (!currentcustomer.id) {
      noerror = false;
      //console.log(`${currentcustomer.id}, Customer ID issue`);
    } else if (!currentcustomer.type) {
      noerror = false;
      //console.log(`${currentcustomer.type}, Customer Type issue`);
    } else if (currentcustomer.type === "Business" && !currentcustomer.name) {
      noerror = false;
      //console.log(`${currentcustomer.name}, Customer Name issue`);
    } else if (!currentcustomer.contact) {
      noerror = false;
      //console.log(`${currentcustomer.contact}, Customer Contact issue`);
    } else if (!currentcustomer.phone) {
      noerror = false;
      //console.log(`${currentcustomer.phone}, Customer Phone issue`);
    } else if (!currentcustomer.address) {
      noerror = false;
      //console.log(`${currentcustomer.address}, Customer Address issue`);
    }
    if (noerror !== submitbtn.customer) {
      setSubmitbtn({ ...submitbtn, customer: noerror });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentcustomer]);

  //Hook for the checkbox input
  const [checkbox, setCheckbox] = useState({
    samples: false,
    flyers: false,
    instructioncard: false,
    posters: false,
    props: false,
    promocard: false,
  });

  //Hook for customer profile update dialog box
  const [customerupdatedialog, setCustomerupdatedialog] = useState(false);

  //Hook for the SnackBar
  const [snackopen, setSnackopen] = useState(false);

  //Hook for write to db
  const [writetodb, setWritetodb] = useState({
    order: false,
    customer: false,
  });
  useEffect(() => {
    //console.log(writetodb);
    let writing = true;
    if (writetodb.order) {
      const writedb = async () => {
        
        //Reset function
        const reset = () => {
          //Reset the form
          setWritetodb({
            order: false,
            customer: false,
          });
          setSnackopen(false);
          setOrders({
            ...orderobj,
            order: [
              { itemid: "", name: "", qty: "", price: "", description: "" },
            ],
          }); //Put the emply obj to orders hook
          setCurrentcustomer({...customerobj, list_displayname: "",}); //Put the emply obj to currentcustomer hook
          setCheckbox(
            //Put the emply obj to checkbox hook
            {
              samples: false,
              flyers: false,
              instructioncard: false,
              posters: false,
              props: false,
              promocard: false,
            }
          );
          setSubmitbtn({
            order: false,
            customer: false,
          });
        }

        //Upload PO image
        const uploadimg = (orderid) => {
          const uploadimgurl = sessionStorage.getItem("uploadimgurl");
          if (uploadimgurl) {
            const {url, path} = JSON.parse(uploadimgurl);
            let extension = path.split(".");
            const serverpath=`orders/${orderid}_customerpo.${extension[extension.length - 1]}`;
            const storageRef = ref(storage, serverpath);
            uploadString(storageRef, url, "data_url")
            .then((snapshot) => {
              //Successfully uploaded
              //console.log(snapshot);
              
              //STORE the path to DB
              let fileobj = { customerpofilepath: serverpath };
              dataupdate("orders", orderid, fileobj).then(
                (res) => {
                  //console.log(`${res} image path is added!`);
                  sessionStorage.clear();
                  if (writing) {
                    //Reset the form
                    reset();
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            })
            .catch((error) => {
              console.log(error);
            });
          } else {
            //Reset the form
            reset();
          }
        }

        const updatecustomerlist = (id) => {
          console.log(id)
          let templist = [...customerlist]
          console.log(templist)
          if (currentcustomer.id==="new") {
            templist.push({
              ...currentcustomer,
              id,
              list_displayname: currentcustomer.type === 'User' ? currentcustomer.contact : currentcustomer.name,
            })
          } else {
            let index = templist.findIndex((customer)=>customer.id===currentcustomer.id)
            templist[index] = {...currentcustomer}
          }
          console.log(templist)
          setCustomerlist([...templist])
        }

        if (currentcustomer.id === "new") {
          //Create new customer profile in the DB
          try {
            const ctrdocRef = await addDoc(collection(db, "customers"), {
              name: currentcustomer.name,
              type: currentcustomer.type,
              contact: currentcustomer.contact,
              phone: currentcustomer.phone,
              email: currentcustomer.email,
              address: currentcustomer.address,
              address2: currentcustomer.address2,
              payment: currentcustomer.payment,
              remarks: currentcustomer.remarks,
            });
            if (ctrdocRef.id && writing) {
              //After created the customer profile in the DB, Write the order details in the DB
              try {
                const docRef = await addDoc(collection(db, "orders"), {
                  ...orders,
                  paymentstatus: "unsettled",
                  customerid: ctrdocRef.id,
                  name: currentcustomer.name,
                  type: currentcustomer.type,
                  contact: currentcustomer.contact,
                  phone: currentcustomer.phone,
                  email: currentcustomer.email,
                  address: currentcustomer.address,
                  address2: currentcustomer.address2,
                  ctrremarks: currentcustomer.remarks,
                });
                if ((writing)&&(docRef.id)) {
                  //console.log("Document written with ID: ", docRef.id);
                  uploadimg(docRef.id);
                  updatecustomerlist(ctrdocRef.id)
                }
              } catch (e) {
                //error handling
                console.log(e);
              }
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          //Existing customer profile
          try {
            //Write the order details in the DB
            const docRef = await addDoc(collection(db, "orders"), {
              ...orders,
              paymentstatus: "unsettled",
              customerid: currentcustomer.id,
              name: currentcustomer.name,
              type: currentcustomer.type,
              contact: currentcustomer.contact,
              phone: currentcustomer.phone,
              email: currentcustomer.email,
              address: currentcustomer.address,
              address2: currentcustomer.address2,
              ctrremarks: currentcustomer.remarks,
            });
            if ((writing)&&(docRef.id)) {
              //console.log("Document written with ID: ", docRef.id);
              if (writetodb.customer) {
                //Update the customer profile
                //Remove the ID attribute
                const {id, list_displayname, ...ctrprops} = currentcustomer;
                dataupdate("customers", id, ctrprops).then(
                  (res) => {
                    //console.log(`${res} the customer profile!`);
                    uploadimg(docRef.id);
                    updatecustomerlist(id)
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              } else {
                //No modification to customer profile
                uploadimg(docRef.id);
              }
            }
          } catch (e) {
            //error handling
            console.log(e);
          }
        }
      };
      writedb();
    }
    return () => {
      writing = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writetodb]);

  //Store the input value into the hook orders
  const handleChange = (prop) => (event, value) => {
    if (prop === "channels" && event.target.value !== "sales") {
      //For selecting channel and not Sales Person
      setOrders({
        ...orders,
        [prop]: event.target.value,
        sales: "",
        error: "",
      });
    } else if (prop === "customerid") {
      //For selecting customer
      if (value){
        if (value.id !== "new") {
          //Customers in the list
          let customer = customerlist.filter(
            (customer) => customer.id === value.id
          );
          //console.log(customer[0])
          setCurrentcustomer({
            id: value.id,
            name: customer[0].name,
            list_displayname: customer[0].type === 'User' ? customer[0].contact : customer[0].name,
            type: customer[0].type,
            contact: customer[0].contact,
            phone: customer[0].phone,
            email: customer[0].email,
            address: customer[0].address,
            address2: customer[0].address2,
            payment: customer[0].payment,
            remarks: customer[0].remarks,
          });
        } else {
          //New customer
          setCurrentcustomer({
            id: "new",
            name: "",
            list_displayname: "",
            type: "",
            contact: "",
            phone: "",
            email: "",
            address: "",
            address2: "",
            payment: "",
            remarks: "",
          });
        }
      }
    } else if (
      prop === "flyers" ||
      prop === "instructioncard" ||
      prop === "posters" ||
      prop === "props" ||
      prop === "promocard"
    ) {
      //For Selected checkbox quantity
      let inputvalue = parseInt(event.target.value); //Make sure it is in integer
      if (inputvalue < 1) {
        //Must larger than 0
        inputvalue = 1;
      }
      setOrders({ ...orders, [prop]: inputvalue, error: "" });
    } else {
      //For Selected Sales Person in Channel or Sales in Sales Person
      setOrders({ ...orders, [prop]: event.target.value, error: "" });
    }
  };

  //Store the customer
  const customerhandleChange = (prop) => (event) => {
    if (prop === "type") {
      //In case it is general customer
      setCurrentcustomer({
        ...currentcustomer,
        type: event.target.value,
        name: event.target.value === "User" ? "General Customer" : "",
      });
    } else if ((prop === "name")&&(currentcustomer.type === "Business")){
      setCurrentcustomer({ ...currentcustomer, [prop]: event.target.value, list_displayname: event.target.value, });
    } else if ((prop === "contact")&&(currentcustomer.type === "User")){
      setCurrentcustomer({ ...currentcustomer, [prop]: event.target.value, list_displayname: event.target.value, });
    } else {
      //The rest of input field
      setCurrentcustomer({ ...currentcustomer, [prop]: event.target.value });
    }
  };

  //Store the order in the array
  const itemhandleChange = (prop) => (event, value) => {
    let neworderobj = { ...orders, error: "" }; //Extract the data from hook orders and store in a variable neworderobj
    if (prop.input === "itemid") {
      //In case change the item
      if (value) {
        //Extract the price from database
        let pricetag =
          currentcustomer.type === "Business"
            ? itemlist.filter((item) => item.itemid === value.itemid)[0].dsp
            : itemlist.filter((item) => item.itemid === value.itemid)[0].rsp;
        //Extract the description from database
        let description = itemlist.filter((item) => item.itemid === value.itemid)[0].description;
        neworderobj.order[prop.index] =
          //Updating the item id in this order
          {
            ...orders.order[prop.index],
            itemid: value.itemid,
            name: value.name,
            qty: 1, //default value
            price: pricetag,
            description,
          };
      } else {
        neworderobj.order[prop.index] =
          //Updating the item id in this order
          {
            ...orders.order[prop.index],
            itemid: "",
            name: "",
            qty: "",
            price: "",
            description: "",
          };

      }
    } else if (prop.input === "qty") {
      //In case change the qty

      let inputvalue;
      if (!event.target.value) {
        inputvalue = event.target.value
      } else {
        inputvalue = parseInt(event.target.value) >= 1 ? parseInt(event.target.value) : 1
      }

      neworderobj.order[prop.index] =
        //Updating the qty/price in this order
        {
          ...orders.order[prop.index],
          [prop.input]: inputvalue,
        };
    } else if (prop.input === "price") {
        //In case change the price

        let inputvalue = event.target.value
        if (inputvalue) {
            if (parseFloat(inputvalue) < 0) {
                // NO -xxx
                inputvalue = 0
            } else {
                //Convert to string
                let text = parseFloat(event.target.value).toFixed(2).toString();
                if (text.charAt(text.length-1)==="0") {
                    if (text.charAt(text.length-2)==="0") {
                        // xxx.00
                        inputvalue = parseInt(event.target.value)
                    } else {
                        // xxx.x0
                        inputvalue = parseFloat(event.target.value).toFixed(1)
                    }
                } else {
                    // xxx.xx
                    inputvalue = parseFloat(event.target.value).toFixed(2)
                }
            }
        }
    
        neworderobj.order[prop.index] =
        //Updating the qty/price in this order
        {
          ...orders.order[prop.index],
          [prop.input]: inputvalue,
        };
    } else {
      //In case change description
      neworderobj.order[prop.index] =
        //Updating the description in this order
        {
          ...orders.order[prop.index],
          [prop.input]: event.target.value,
        };
    }
    //Update the total amount
    neworderobj.total = 0;
    neworderobj.order.forEach((order) => {
      if (order.price > 0 && order.qty > 0) {
        neworderobj.total = parseFloat(
          parseFloat(order.price) * parseFloat(order.qty) +
            parseFloat(neworderobj.total)
        ).toFixed(2);
      }
    });
    setOrders(neworderobj); //Update the hook
  };

  //delete the row from the order array
  const deleteitem = (prop) => () => {
    let neworderobj = { ...orders, error: "" }; //Extract the data from hook orders and store in a variable neworderobj
    if (prop === 0 && neworderobj.order.length === 1) {
      //In case only 1 item row to be deleted, put the empty object
      let blankobj = {
        //Object
        itemid: "",
        name: "",
        qty: "",
        price: "",
        description: "",
      };
      neworderobj.order.splice(prop, 1, blankobj);
    } else {
      neworderobj.order.splice(prop, 1); //In case delete any 1 of item row
    }
    //Update the total amount
    neworderobj.total = 0;
    neworderobj.order.forEach((order) => {
      if (order.price > 0 && order.qty > 0) {
        neworderobj.total = parseFloat(
          parseFloat(order.price) * parseFloat(order.qty) +
            parseFloat(neworderobj.total)
        ).toFixed(2);
      }
    });
    setOrders(neworderobj); //Update the hook

    //The Item Selection List
    if (itemlistopen.length===1) {
      setItemlistopen([false]);
    } else {
      let tempopenlist = [...itemlistopen];
      tempopenlist.splice(prop, 1);
      setItemlistopen([...tempopenlist]);
    }
  };

  //Add 1 row from the order array
  const additem = () => {
    let neworderobj = { ...orders, error: "" };
    neworderobj.order.push(
      //Add 1 empty object at the end of the array
      {
        //Object
        itemid: "",
        name: "",
        qty: "",
        price: "",
        description: "",
      }
    );
    setOrders(neworderobj);
    //The Item Selection List
    let tempopenlist = [...itemlistopen]
    tempopenlist.push(false)
    setItemlistopen([...tempopenlist])
  };

  //Handle the Checkbox changes
  const checkboxhandlechange = (prop) => (event) => {
    setCheckbox({ ...checkbox, [prop]: event.target.checked }); //Update the Checkbox hook
    //Put the initial value
    if (!event.target.checked) {
      setOrders({ ...orders, [prop]: "", error: "" });
    } else if (prop === "flyers" || prop === "instructioncard") {
      setOrders({ ...orders, [prop]: 20, error: "" });
    } else if ((prop === "posters") || (prop === "props") || (prop === "promocard")) {
      setOrders({ ...orders, [prop]: 1, error: "" });
    }
  };

  //Reset the form
  const handlereset = () => {
    setOrders({
      ...orderobj,
      order: [{ itemid: "", name: "", qty: "", price: "", description: "" }],
    }); //Put the emply obj to orders hook
    setCurrentcustomer({...customerobj, list_displayname: "",}); //Put the emply obj to currentcustomer hook
    setCheckbox(
      //Put the emply obj to checkbox hook
      {
        samples: false,
        flyers: false,
        instructioncard: false,
        posters: false,
        props: false,
        promocard: false,
      }
    );
    setSubmitbtn({
      order: false,
      customer: false,
    });
    setWritetodb({
      order: false,
      customer: false,
    });
  };

  //Handle submit
  const handlesubmit = () => {
    let tempdelivery;
    let tempstatus;
    let temppayment;
    if (!orders.deliverymethod) {
      if (currentcustomer.type === "User") {
        tempdelivery = "hkpost";
      } else {
        tempdelivery = "sf";
      }
    }
    if (!orders.orderstatus) {
      tempstatus = "onhold";
    }
    if (!orders.orderpayment) {
      temppayment = currentcustomer.payment
        ? currentcustomer.payment
        : "Advanced";
    }
    let temparrayobj = orders.order.map((obj)=>{
      delete obj.name;
      return obj.price ? obj : {...obj, price: 0,}
    });
    setOrders({
      ...orders,
      deliverymethod: tempdelivery ? tempdelivery : orders.deliverymethod,
      orderstatus: tempstatus ? tempstatus : orders.orderstatus,
      orderpayment: temppayment ? temppayment : orders.orderpayment,
      order: temparrayobj,
      error: "",
    });
    if (currentcustomer.id === "new") {
      //New customer
      //console.log("New customer!")
      setSnackopen(true);
      setWritetodb({
        order: true,
        customer: false,
      });
    } else {
      let initialcustomer = customerlist.filter(
        (customer) => customer.id === currentcustomer.id
      );
      if (JSON.stringify(currentcustomer) === JSON.stringify(initialcustomer[0])) {
        //Existing customer without modification
        //console.log("No Change!")
        setSnackopen(true);
        setWritetodb({
          order: true,
          customer: false,
        });
      } else {
        //Existing customer and MODIFIED!!!
        //console.log("Customer data changed!")
        setCustomerupdatedialog(true)
      }
    }
  };

  const handledialogno = () => {
    //Existing customer, modified BUT not update
    //console.log("NO UPDATE!")
    setSnackopen(true);
    setWritetodb({
      order: true,
      customer: false,
    });
    setCustomerupdatedialog(false)
  }

  const handledialogupdate = () => {
    //Existing customer, modified and UPDATE!
    //console.log("UPDATE the Customer profile!")
    setSnackopen(true);
    setWritetodb({
      order: true,
      customer: true,
    });
    setCustomerupdatedialog(false)
  }

  //Go to top
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const tophandleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#top',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  //Transition Up
  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" in={snackopen} timeout={2000} />;
  };

  return (
    <Box id="top" className="container">
      <Stack direction="column" alignItems="stretch" spacing={4}>
        <Typography  variant="h1" align="center" sx={{ my: 4 }}>
          ORDER INPUT
        </Typography>
        {
          //Display the captured error message if it exists
          orders.error && (
            <Typography
              id="errormessage"
              variant="body1"
              align="center"
              sx={{ color: "#FF0000" }}
            >
              {orders.error}
            </Typography>
          )
        }
        {
          //Sales Channels
        }
        <Paper variant="outlined" className="paperstyle">
          <Stack justifyContent="flex-start" spacing={4}>
            <Typography  variant="h2">
              Sales Channels
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Order Date"
                value={orders.orderdate}
                onChange={(newValue) =>
                  setOrders({ ...orders, orderdate: newValue })
                }
                renderInput={(params) => (
                  <TextField {...params} />
                )} //Display the input field
                //Display the calendar
                renderDay={(days, selected, pickersDayProps) => (
                  <PickersDay
                    //Display DAY in the calendar
                    {...pickersDayProps}
                  />
                )}
                slots={{
                  day: StyledDay,
                }}
                slotProps={{
                  actionBar: {
                    sx: {
                      "& .MuiButton-root": {
                        color: "#506d4e", // Change button text color
                      },
                    },
                  },
                  textField: {
                    sx: {
                      width: "100%",
                      "@media (min-width:600px)": {
                        width: "300px",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
            <Stack spacing={4}>
              <FormControl className="mfield">
                <InputLabel id="channel-label">Channel</InputLabel>
                <Select
                  labelId="channel-label"
                  id="channel"
                  value={orders.channels ? orders.channels : ""}
                  label="Channel"
                  onChange={handleChange("channels")}
                >
                  {channellist.map((channel) => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                className="mfield"
                required={orders.channels !== "sales" ? false : true}
                disabled={orders.channels !== "sales" ? true : false}
              >
                <InputLabel id="sales-label">Sales Person</InputLabel>
                <Select
                  labelId="sales-label"
                  id="sales"
                  value={orders.sales ? orders.sales : ""}
                  label="Sales Person"
                  onChange={handleChange("sales")}
                >
                  {orders.channels !== "sales" ? (
                    <MenuItem value={""}></MenuItem>
                  ) : (
                    saleslist.map((sales) => (
                      <MenuItem key={sales} value={sales}>
                        {sales}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Paper>
        {
          //Customer Information
        }
        <Paper variant="outlined" className="paperstyle">
          <Stack justifyContent="flex-start" spacing={4}>
            <Typography  variant="h2">
              Customer Information
            </Typography>

            <Autocomplete
              className="lfield"
              id="customer"
              open={customerlistopen}
              onOpen={() => { setCustomerlistopen(true); }}
              onClose={() => { setCustomerlistopen(false); }}
              value={currentcustomer.id ? {...currentcustomer} : null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.list_displayname || ""}
              options={customerlist}
              groupBy={(option) => option.type}
              onChange={handleChange("customerid")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {customerlist.length===0 ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            {
              // Customer info
              currentcustomer.id && (
                <Stack justifyContent="flex-start" spacing={4}>
                  <Stack>
                    <FormControl className="sfield">
                      <InputLabel id="customertype-label">
                        Customer Type
                      </InputLabel>
                      <Select
                        labelId="customertype-label"
                        id="customertype"
                        value={currentcustomer.type}
                        label="Customer Type"
                        disabled={currentcustomer.id !== "new" ? true : false}
                        onChange={customerhandleChange("type")}
                      >
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  {currentcustomer.type === "Business" && ( //Show company input field for Business type only
                    <TextField
                      className="lfield"
                      id="name"
                      label="Company name"
                      variant="outlined"
                      value={currentcustomer.name}
                      onChange={customerhandleChange("name")}
                    />
                  )}
                  <TextField
                    className="mfield"
                    id="contact"
                    label="Contact person"
                    variant="outlined"
                    value={currentcustomer.contact}
                    onChange={customerhandleChange("contact")}
                  />
                  <TextField
                    className="mfield"
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    value={currentcustomer.phone}
                    onChange={customerhandleChange("phone")}
                  />
                  <TextField
                    className="mfield"
                    id="email"
                    label="Email address"
                    variant="outlined"
                    value={currentcustomer.email}
                    onChange={customerhandleChange("email")}
                  />
                  <TextField
                    className="fullfield"
                    id="address"
                    label="Billing Address"
                    variant="outlined"
                    multiline
                    value={currentcustomer.address}
                    onChange={customerhandleChange("address")}
                  />
                  <TextField
                    className="fullfield"
                    id="address2"
                    label="Delivery Address (If different from above)"
                    variant="outlined"
                    multiline
                    value={currentcustomer.address2}
                    onChange={customerhandleChange("address2")}
                  />
                  <TextField
                    className="fullfield"
                    id="ctrremarks"
                    label="Remarks (Customer)"
                    variant="outlined"
                    multiline
                    value={currentcustomer.remarks}
                    onChange={customerhandleChange("remarks")}
                  />
                  <Stack>
                    <FormControl className="sfield">
                      <InputLabel id="payment-label">Payment Type</InputLabel>
                      <Select
                        labelId="payment-label"
                        id="payment"
                        value={currentcustomer.payment}
                        label="Payment Type"
                        onChange={customerhandleChange("payment")}
                      >
                  {paymentlist.map((payment) => (
                    <MenuItem key={payment.id} value={payment.id}>
                      {payment.display}
                    </MenuItem>
                  ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Payment Due Date"
                      value={orders.paymentduedate}
                      onChange={(newValue) =>
                        setOrders({ ...orders, paymentduedate: newValue })
                      }
                      renderInput={(params) => (
                        <TextField {...params} />
                      )} //Display the input field
                      //Display the calendar
                      renderDay={(days, selected, pickersDayProps) => (
                        <PickersDay
                          //Display DAY in the calendar
                          {...pickersDayProps}
                        />
                      )}
                      slots={{
                        day: StyledDay,
                      }}
                      slotProps={{
                        actionBar: {
                          sx: {
                            "& .MuiButton-root": {
                              color: "#506d4e", // Change button text color
                            },
                          },
                        },
                        textField: {
                          sx: {
                            width: "100%",
                            "@media (min-width:600px)": {
                              width: "300px",
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              )
            }
          </Stack>
        </Paper>
        {
          //Order Items
        }
        <Paper variant="outlined" className="paperstyle">
          <Stack justifyContent="flex-start" spacing={4}>
            <Typography  variant="h2">
              Order Details
            </Typography>
            <TextField
              className="mfield"
              id="PO"
              label="Customer PO / Ref. No."
              variant="outlined"
              value={orders.customerpo}
              onChange={handleChange("customerpo")}
            />
            <Typography  variant="body2">
              Upload the customer PO or ref Doc.
            </Typography>
            <Filehandle
              fileinfo={{
                type: "input_po",
                path: "",
                lang: "en",
              }}
            />
            <Divider />
            {
              //Item Input
              orders.order &&
                orders.order.map((order, index) => (
                  <Stack key={index} spacing={4}>
                    {index > 0 && <Divider />}
                    <Stack spacing={2}>
                      <Stack>
                        <Autocomplete
                          className="lfield"
                          id={`item${index}`}
                          disabled={currentcustomer.id ? false : true}
                          open={itemlistopen[index]}
                          onOpen={() => {
                            let tempopenlist = [...itemlistopen]
                            tempopenlist[index]=true
                            setItemlistopen([...tempopenlist])
                          }}
                          onClose={() => { 
                            let tempopenlist = [...itemlistopen]
                            tempopenlist[index]=false
                            setItemlistopen([...tempopenlist])
                           }}
                          value={order.itemid ? {...order} : null} //The value selected will be shown in the menu. The object "order" is put here
                          isOptionEqualToValue={(option, value) => option.itemid === value.itemid} //The attribute used to identified the selection - here is the itemid
                          getOptionLabel={(option) => option.name || ""} //Display name ONLY. Nothing to do with the value
                          options={itemlist} //Selection list
                          groupBy={(option) => option.display_group} //Category by specific attribute
                          onChange={itemhandleChange({
                            input: "itemid",
                            index,
                          })}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Order item"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {itemlist.length===0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />

                      </Stack>
                      <TextField
                        className="fullfield"
                        id={`desc${index}`}
                        label="Description"
                        placeholder="Description"
                        variant="outlined"
                        //multiline
                        //rows={2}
                        value={order.description}
                        disabled={order.itemid ? false : true}
                        onChange={itemhandleChange({
                          input: "description",
                          index,
                        })}
                      />
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        spacing={4}
                      >
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          alignItems="flex-start"
                          spacing={{ xs: 2, sm: 4 }}
                        >
                          <TextField
                            className="sfield"
                            id={`qty${index}`}
                            label="Qty."
                            type="number"
                            value={order.qty}
                            disabled={order.itemid ? false : true}
                            onChange={itemhandleChange({ input: "qty", index })}
                          />
                          <TextField
                            className="sfield"
                            id={`price${index}`}
                            label="Unit Price"
                            type="number"
                            value={order.price}
                            disabled={order.itemid ? false : true}
                            onChange={itemhandleChange({
                              input: "price",
                              index,
                            })}
                          />
                        </Stack>
                        <IconButton
                          aria-label="delete"
                          onClick={deleteitem(index)}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                ))
            }
            <Stack alignItems="center">
              <IconButton //Add new Item's button
                color="secondary"
                aria-label="add"
                onClick={additem}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </Stack>
            <Typography variant="body1">
              Total Amount: ${orders.total}
            </Typography>
          </Stack>
        </Paper>
        {
          //Additional Request
          /*
        <Paper variant="outlined" className="paperstyle">
          <Stack justifyContent="flex-start" spacing={4}>
            <Typography  variant="h2">
              Additional Request
            </Typography>
            {
              //Samples
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Samples"
                control={
                  <Checkbox
                    checked={checkbox.samples}
                    onChange={checkboxhandlechange("samples")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="mfield"
                id="samples"
                label="Samples details"
                placeholder="Details"
                variant="outlined"
                size="small"
                multiline
                value={orders.samples}
                disabled={checkbox.samples ? false : true}
                onChange={handleChange("samples")}
              />
            </Stack>
            {
              //Flyers
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Flyers"
                control={
                  <Checkbox
                    checked={checkbox.flyers}
                    onChange={checkboxhandlechange("flyers")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="sfield"
                id="flyersqty"
                label="Qty."
                type="number"
                size="small"
                value={orders.flyers}
                disabled={checkbox.flyers ? false : true}
                onChange={handleChange("flyers")}
              />
            </Stack>
            {
              //Instruction Cards
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Instruction Card"
                control={
                  <Checkbox
                    checked={checkbox.instructioncard}
                    onChange={checkboxhandlechange("instructioncard")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="sfield"
                id="Instructions Card"
                label="Qty."
                type="number"
                size="small"
                value={orders.instructioncard}
                disabled={checkbox.instructioncard ? false : true}
                onChange={handleChange("instructioncard")}
              />
            </Stack>
            {
              //Promotion Cards
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Promotion Card"
                control={
                  <Checkbox
                    checked={checkbox.promocard}
                    onChange={checkboxhandlechange("promocard")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="sfield"
                id="Promotion Card"
                label="Qty."
                type="number"
                size="small"
                value={orders.promocard}
                disabled={checkbox.promocard ? false : true}
                onChange={handleChange("promocard")}
              />
            </Stack>
            {
              //Posters
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Poster"
                control={
                  <Checkbox
                    checked={checkbox.posters}
                    onChange={checkboxhandlechange("posters")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="sfield"
                id="Poster"
                label="Qty."
                type="number"
                size="small"
                value={orders.posters}
                disabled={checkbox.posters ? false : true}
                onChange={handleChange("posters")}
              />
            </Stack>
            {
              //Props
            }
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="flex-start"
            >
              <FormControlLabel
                className="sfield"
                label="Props"
                control={
                  <Checkbox
                    checked={checkbox.props}
                    onChange={checkboxhandlechange("props")}
                    disabled={currentcustomer.id ? false : true}
                  />
                }
              />
              <TextField
                className="sfield"
                id="Props"
                label="Qty."
                type="number"
                size="small"
                value={orders.props}
                disabled={checkbox.props ? false : true}
                onChange={handleChange("props")}
              />
            </Stack>
          </Stack>
        </Paper>
          */
        }
        {
          //Delivery Terms, Status & Remarks
        }
        <Paper variant="outlined" className="paperstyle">
          <Stack justifyContent="flex-start" spacing={4}>
            <Typography  variant="h2">
              Delivery Terms and Status
            </Typography>
            <Stack spacing={4}>
              <FormControl className="sfield">
                <InputLabel id="Delivery-label">Delivery</InputLabel>
                <Select
                  labelId="Delivery-label"
                  id="delivery"
                  value={
                    orders.deliverymethod === ""
                      ? currentcustomer.type === "User"
                        ? "hkpost"
                        : "sf"
                      : orders.deliverymethod
                  }
                  label="Delivery method"
                  onChange={handleChange("deliverymethod")}
                  disabled={currentcustomer.id ? false : true}
                >
                 {deliverylist.map((delivery) => (
                    <MenuItem key={delivery.id} value={delivery.id}>
                      {delivery.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="sfield">
                <InputLabel id="orderstatus-label">Order Status</InputLabel>
                <Select
                  labelId="Orderstatus-label"
                  id="Orderstatus"
                  value={
                    orders.orderstatus === "" ? "onhold" : orders.orderstatus
                  }
                  label="Order Status"
                  onChange={handleChange("orderstatus")}
                  disabled={currentcustomer.id ? false : true}
                >
                  {
                    //Only the Admin can choose "Delivered" and "Finished" in the input form
                    permission["orderinput"]["statuslistoption"][type] ? 
                      orderstatuslist.map((orderstatus) => 
                        <MenuItem key={orderstatus.id} value={orderstatus.id}>
                          {orderstatus.display}
                        </MenuItem>
                      )
                      :
                      orderstatuslist.filter((option)=>{
                        //The NON-ADMIN user type can choose "On Hold" and "Delivery" only
                        return option.id==="onhold" || option.id==="packing" || option.id==="delivery"
                        }).map((orderstatus) => 
                          <MenuItem key={orderstatus.id} value={orderstatus.id}>
                            {orderstatus.display}
                          </MenuItem>
                        )
                  }
                </Select>
              </FormControl>
            </Stack>
            <TextField
              className="fullfield"
              id="remarks"
              label="Remarks"
              placeholder="Remarks"
              variant="outlined"
              multiline
              value={orders.remarks}
              onChange={handleChange("remarks")}
              disabled={currentcustomer.id ? false : true}
            />
          </Stack>
        </Paper>
        {
          //Reset & Submit
        }
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ py: "32px" }}
        >
          <Button variant="contained" onClick={handlereset} disableElevation>
            RESET
          </Button>
          <Button
            variant="contained"
            onClick={handlesubmit}
            disabled={submitbtn.order && submitbtn.customer ? false : true}
            disableElevation
          >
            SUBMIT
          </Button>
        </Stack>
        {
          //Go to TOP
        }
        <Zoom in={trigger}>
          <Box
            onClick={tophandleClick}
            role="presentation"
            sx={{ position: 'fixed', bottom: 24, right: 24 }}
          >
            <Fab color="secondary" size="medium" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
        {
          //Snackbar
        }
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={TransitionUp}
        >
          <Alert
            severity="info"
            sx={{ width: "100%", justifyContent: "center", py: "8px" }}
          >
            Updating now ...
          </Alert>
        </Snackbar>
        {
          //Customer update confirmation Dialog
        }
        <Dialog open={customerupdatedialog}>
          <DialogTitle>
            <p>The customer information has been modified.</p>
            <p>Do you want to update it in the CUSTOMER PROFILE too?</p>
          </DialogTitle>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                variant="contained"
                onClick={handledialogno}
              >
                No
              </Button>
              <Button
                variant="outlined"
                sx={{color: "#739c70"}}
                disableElevation
                onClick={handledialogupdate}
              >
                UPDATE
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}
