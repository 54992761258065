//Page permission
export const permission = {
  //input order page
  orderinput: {
    admin: true,
    poweruser: true,
    gladsales: true,
    allsales: true,
    warehouse: false,
    pos: false,
    //Specific item list selection
    specificitemlist: {
      admin: ['QCHEFS', 'PHILOPET', 'ThermoSwitch', 'BURBUR', 'g2h', 'BionTech', 'BOGAR', 'MYCANI', 'SOPRA', 'Promotion'],
      poweruser: ['QCHEFS', 'PHILOPET', 'g2h'],
      gladsales: ['QCHEFS', 'PHILOPET', 'g2h'],
      allsales: ['QCHEFS', 'PHILOPET', 'ThermoSwitch', 'BURBUR', 'g2h', 'BOGAR', 'MYCANI', 'SOPRA', 'Promotion'],
    },
    //Display the "delivered" and "finished" in the selection list
    statuslistoption: {
      admin: true, //Can display everything
      poweruser: false,
      gladsales: false,
      allsales: false,
      warehouse: false,
      pos: false,
    },
  },
  //order list page
  orderlist: {
    admin: true,
    poweruser: true,
    gladsales: true,
    allsales: true,
    warehouse: true,
    pos: false,

    //Display the Delivered status only
    showdeliveredonly: {
      admin: false, //Can display everything
      poweruser: false,
      gladsales: false, //Can display everything
      allsales: false, //Can display everything
      warehouse: true,
      pos: false,
    },

    //Deliverable list
    deliverablelist: {
      admin: true, 
      poweruser: false,
      gladsales: false, 
      allsales: false, 
      warehouse: true,
      pos: false,
    }
  },
  //order list page
  orderdetail: {
    admin: true,
    poweruser: true,
    gladsales: true,
    allsales: true,
    warehouse: true,
    pos: false,

    //Edit in order list page
    edit: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },

    //Sections in order list page
    po: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },
    invoice: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },
    paymentstatus: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },
    itemprice: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },
    additionalcharges: {
      admin: true,
      poweruser: true,
      gladsales: true,
      allsales: true,
      warehouse: false,
      pos: false,
    },
    delivereddialog: {
      admin: false,
      poweruser: false,
      gladsales: false, 
      allsales: false, 
      warehouse: true,
      pos: false,
    },
  },
  //Inventory page
  inventory: {
    admin: true,
    poweruser: true,
    gladsales: true, 
    allsales: true, 
    warehouse: false,
    pos: false,
    //Able to edit the inventory data
    edit: {
      admin: true, //Can edit
      poweruser: true,
      gladsales: false, 
      allsales: false, 
      warehouse: false,
      pos: false,
    },
  },
  //Item List page
  itemlist: {
    admin: true,
    poweruser: false,
    gladsales: false, 
    allsales: false, 
    warehouse: false,
    pos: false,
  },
  //POS
  pos: {
    admin: true,
    poweruser: false,
    gladsales: false,
    allsales: false,
    warehouse: false,
    pos: true,
  },
};

//For orderinput, order object 
export const orderobj = {
  channels: "",
  sales: "",
  ctrremarks: "",
  customerpo: "",
  customerpofilepath: "",
  order: [
    //Array
    {
      //Object
      itemid: "",
      name: "",
      qty: "",
      price: "",
      description: "",
    },
  ],
  total: "",
  samples: "",
  flyers: "",
  instructioncard: "",
  posters: "",
  props: "",
  promocard: "",
  otherfilespath: "",
  orderdate: "",
  orderstatus: "",
  orderpayment: "",
  paymentduedate: "",
  paiddate: "",
  paymentstatus: "",
  paymentfilepath: "",
  remarks: "",
  deliverydate: "",
  deliverymethod: "",
  courierfilepath: "",
  invoice: "",
  invoicefilepath: "",
  dn: "",
  dnfilepath: "",
  error: "",
};

//For orderinput, customer object
export const customerobj = {
  id: "",
  name: "",
  type: "",
  contact: "",
  phone: "",
  email: "",
  address: "",
  address2: "",
  payment: "",
  channels: "",
  sales: "",
  remarks: "",
};

//For orderinput, Sales List
export const saleslist = ["Stephanie", "Tung", "Doris", "Angel", "Ivan", "Zeke"];

//For orderinput, Channel list
export const channellist = [
  { id: "sales", display: "Sales Person", cdisplay: "業務員", },
  { id: "web", display: "Website", cdisplay: "網頁", },
  { id: "whatsapp", display: "WhatsApp", cdisplay: "WhatsApp", },
  { id: "messenger", display: "Facebook Messenger" , cdisplay: "臉書通訊工具", },
  { id: "fb", display: "Facebook", cdisplay: "臉書", },
  { id: "ig", display: "Instagram", cdisplay: "Instagram", },
  { id: "carousell", display: "Carousell", cdisplay: "Carousell", },
  { id: "show", display: "Pet Show", cdisplay: "寵物展", },
  { id: "kiosk", display: "Sales Kiosk", cdisplay: "展銷攤位", },
  { id: "others", display: "Others", cdisplay: "其他", },
];

//For orderinput, Payment List
export const paymentlist = [
  { id: "Advanced", display: "Advanced", cdisplay: "收款後出貨", },
  { id: "COD", display: "COD", cdisplay: "貨到付款", },
  { id: "EOM", display: "EOM", cdisplay: "月尾結數", },
];

//For orderinput, Payment Status List
export const paystatuslist = [
  { id: "unsettled", display: "Unsettled", cdisplay: "未付款", },
  { id: "paid", display: "Paid", cdisplay: "已付款", },
  { id: "overdue", display: "Overdue", cdisplay: "逾期未付", },
];

//For orderinput, Order Status List
export const orderstatuslist = [
  { id: "onhold", display: "On Hold", cdisplay: "待確認訂單", },
  { id: "packing", display: "Packing", cdisplay: "執貨", },
  { id: "packed", display: "Packed", cdisplay: "已執好貨", },
  { id: "delivery", display: "Delivery", cdisplay: "安排出貨", },
  { id: "delivered", display: "Delivered", cdisplay: "已出貨", },
  { id: "finished", display: "Finished", cdisplay: "完成訂單", },
];

//For orderinput, Delivery List
export const deliverylist = [
  { id: "sf", display: "SF Express", cdisplay: "順豐", },
  { id: "sfctrpay", display: "SF Express (Customer Pay)", cdisplay: "順豐 (到付)", },
  //{ id: "dw", display: "D & W", cdisplay: "D & W", },
  //{ id: "dwctrpay", display: "D & W (Customer Pay)", cdisplay: "D & W (到付)", },
  { id: "hkpost", display: "HK Post", cdisplay: "香港郵政", },
  { id: "driver", display: "Driver", cdisplay: "司機送貨", },
  { id: "collect", display: "Customer Collect", cdisplay: "客戶自取", },
];

//For navbar navigation menu bar
export const pages = [
  { id: "orderinput", display: "Order Input", cdisplay: "新訂單", },
  { id: "orderlist", display: "Order List", cdisplay: "訂單一覽", },  
  //{ id: "inventory", display: "Inventory", cdisplay: "存貨", }, 
  { id: "itemlist", display: "Item List", cdisplay: "產品列表", }, 
  { id: "pos", display: "POS", cdisplay: "零售", },
];
export const settings = [
  { id: "logout", display: "Logout", cdisplay: "登出", },
];

//For orderlist, filter object
export const filterobj = {
  orderdatefrom: '',
  orderdateto: '',
  paiddatefrom: '',
  paiddateto: '',
  deliverydatefrom: '',
  deliverydateto: '',
  paymentstatus: '',
  orderstatus: '',
  monthlyreport: '',
  customers: '',
  sales: '',
  invoice: '',
  dn: '',
  items: '',
};

//For orderlist, filter object
export const dateselection = [
  { display: "Order Date", id: "order" },
  { display: "Paid Date", id: "paid" },
  { display: "Delivery Date", id: "delivery" },
]

//For orderlist, the table header
export const tableheader = [
  { id: "orderdate", display: "Order Date", cdisplay: "落單日期", },
  { id: "channels", display: "Channels", cdisplay: "銷售平台", },
  { id: "name", display: "Customer", cdisplay: "客戶名稱", },
  { id: "contact", display: "Contact", cdisplay: "聯絡人", },
  { id: "orderstatus", display: "Status", cdisplay: "訂單狀態", },
  { id: "paymentstatus", display: "Payment", cdisplay: "付款狀態", },
  { id: "paymentduedate", display: "Due", cdisplay: "付款限期", },
  { id: "paiddate", display: "Paid", cdisplay: "繳付日期", },
];

//For Item Object in itemlist page
export const itemobj = {
  brand: "",
  category: "",
  cdescription: "",
  cname: "",
  cost: "",
  description: "",
  dsp: "",
  inventory: {
    consignment: [{
      customerid: "",
      exp: "",
      qty: "",
    }],
    sub: [{
      exp: "",
      qty: "",
    }],
    total: "",
  },
  name: "",
  rsp: "",
  stocktake: [{
    date: "",
    stocks: [{
      exp: "",
      qty: "",
    }],
    total: "",
  }],
  target: "",
}

export const deliverytableheader = ['Brand', 'ID', 'Item', 'Qty', 'Packed'];
