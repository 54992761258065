import React from "react";
import '../Global.css'
import { styled } from '@mui/material/styles';
import {
    Paper,
    Box,
    Typography,
    Stack,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "16px",
  width: "30%",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  "@media (min-width:600px)": {
    padding: "32px",
    width: "150px",
    height: "150px",
  },
  '&:hover': {
    cursor: "pointer",
    background: "#50C878",
    color: "#ffffff",
  },
}));

export default function POS() {
  let navigate = useNavigate();

  return(
  <Box className="container">
      <Stack direction="column" alignItems="stretch" spacing={4}>
          <Typography paragraph variant="h1" align="center" sx={{ my: 4 }}>
              Retail Sales Input System
          </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={8}
        sx={{pt: 8}}
      >
        <StyledPaper onClick={()=>{navigate(`/pos/newsales`)}} elevation={2} >
          <Typography variant="h1">
            New Sales
          </Typography>
        </StyledPaper>
        <StyledPaper onClick={()=>{navigate(`/pos/history`)}} elevation={2}>
          <Typography variant="h1">
            History
          </Typography>
        </StyledPaper>
      </Stack>
  </Box>
  )
}