import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../Global.css'
import {
  Paper,
  Box,
  Typography,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { db } from "../../firebase/initfirebase";
import {
  collection,
  doc,
  getDoc,
  //getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { datafetching, dateconvert, itemlistsorting } from "../../firebase/managedb";
import Filehandle from './Filehandle';
import Dialogbox from './Dialogbox';
import { permission, orderstatuslist, channellist, deliverylist, paystatuslist, paymentlist } from "../listsnobjects";

export default function Orderdetail({prop}) {
  let navigate = useNavigate();
  let params = useParams();
  const {type, lang} = prop;

  //Other Files Array
  const [otherfilearray, setOtherfilearray] = useState();
  
  //Fetch the order details according to the order ID
  const [order, setOrder] = useState();

  //Read the item list from database and put it in hook
  const [itemlist, setItemlist] = useState();

  useEffect(() => {
    let reading = true;
    let orderreading = true;
    const readdb = async () => {
      let templist = [];
      const q = query(
        collection(db, "items"),
        orderBy("brand"),
        orderBy("category"),
        orderBy("name")
      );

      //Process the specific order data
      const orderdataprocess = (querySnapshot) => {
        let orderinfo = {
          ...querySnapshot.data(),
          orderid: params.orderid,
          orderdate: querySnapshot.data().orderdate.toDate(),
          paymentduedate: querySnapshot.data().paymentduedate.toDate(),
          paiddate: querySnapshot.data().paiddate ? querySnapshot.data().paiddate.toDate() : "",
          deliverydate: querySnapshot.data().deliverydate
            ? querySnapshot.data().deliverydate.toDate()
            : "",
        }
        //Add the "name" attribute in order object
        let modorder = orderinfo.order.map((order)=>{
          return {
            ...order,
            name: templist.filter((item)=>item.itemid===order.itemid)[0].name,
          }
        })
        if (orderreading) {
          setOrder({
            ...orderinfo,
            order:[...modorder],
          });
          querySnapshot.data().otherfilespath && setOtherfilearray([...querySnapshot.data().otherfilespath]);
        }
      }
      
      //Fetch the specific order data
      const orderfetching = async () => {
        //Read the order database
        let docRef = doc(db, "orders", params.orderid);
        try {
          const querySnapshot = await getDoc(docRef);
          //console.log (querySnapshot)
          if (querySnapshot && templist) {orderdataprocess(querySnapshot)}
          else {console.log("No data fetched!")}
        } catch (e) {
          //error handling
          console.log(e);
        }
      }
      
      //Process the items data
      const dataprocess = (querySnapshot) => {
        querySnapshot.forEach((item) => {
          let iteminfo = {
            itemid: item.id,
            name: item.data().name,
            description: item.data().description,
            brand: item.data().brand,
            dsp: item.data().dsp,
            rsp: item.data().rsp,
            target: item.data().target,
            category: item.data().category,
            inventory: item.data().inventory,
            display_group: item.data().brand === "g2h" ? `Other Charge - ${item.data().category.toUpperCase()}` : `${item.data().brand.toUpperCase()} - ${item.data().category.toUpperCase()}`,
          };
          templist.push(iteminfo);
        });
        if (reading) {
          setItemlist(templist);
          //Read the order database
          orderfetching();
        }
      }

      //Fetch the item data
      const fetching = async () => {
        const querySnapshot = await datafetching(q)
        if (!querySnapshot.empty) { dataprocess(querySnapshot) }
      }
      fetching();
    };
    readdb();
    return () => {
      reading = false;
      orderreading = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sorteditemlist, setSorteditemlist] = useState();

  const [dialogswitch, setDialogswitch] = useState({
    orderstatus: false,
    paymentstatus: false,
    customerinformation: false,
    orderitems: false,
    samples: false,
    additionalcharges: false,
    deliverystatus: false,
    delivereddialog: false,
    deleteorder: false,
  });

  useEffect(()=>{
    if ((dialogswitch.orderitems)&&(!sorteditemlist)) {
      let sortedlist = itemlistsorting([...itemlist], type);
      console.log(sortedlist)
      setSorteditemlist(sortedlist);
    }
  },[dialogswitch, itemlist, sorteditemlist, type])


  //Single field display template
  const Singlefielddisplay = ({ content }) => {
    return (
      <Stack direction="row" justifyContent="flex-start">
        <Typography variant="body1" sx={{ fontWeight: "bold", mr: "8px" }}>
          {content.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color:
              content.value === "overdue"
                ? "#FF0000"
                : content.value === "delivery"
                ? "#0000FF"
                : "#000000",
          }}
        >
          {content.value}
        </Typography>
      </Stack>
    );
  };


  //Show the GO TO TOP Button
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  //Go to the anchor when clicked
  const tophandleClick = (event) => {
    //Search the anchor
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#top"
    );
    //Go to the anchor
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "start", //Move to the start of the block
      });
    }
  };

  return (
    <Box id="top" className="container">
      <Stack direction="column" alignItems="stretch" spacing={4}>
        <Typography paragraph variant="h1" align="center" sx={{ my: 4 }}>
          { lang==="en" ? "ORDER DETAIL" : "訂單詳情" }
        </Typography>
        {order && itemlist ? (
          <React.Fragment>
            <Paper variant="outlined" className="paperstyle">
              <Stack justifyContent="flex-start" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Typography paragraph variant="h2">
                  { lang==="en" ? "Order Status" : "訂單狀態" }
                  </Typography>
                  {
                    permission["orderdetail"]["edit"][type] && 
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          setDialogswitch({
                            ...dialogswitch,
                            orderstatus: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Order Status:" : "訂單狀態:",
                    value: 
                    lang === "en" ?
                    orderstatuslist.filter((status) => status.id === order.orderstatus)[0].display
                    :
                    orderstatuslist.filter((status) => status.id === order.orderstatus)[0].cdisplay,
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Order Date:" : "落單日期:",
                    value: order.orderdate ? dateconvert(order.orderdate) : "",
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Customer PO / Ref. No.:" : "客戶訂單編號 / 訂單參考編號:",
                    value: order.customerpo ? order.customerpo : "",
                  }}
                />
                {permission["orderdetail"]["po"][type] &&
                  order.orderid && ( //Only Sales and Admin can see the invoice
                    <Filehandle
                      fileinfo={{
                        type: "customerpo",
                        orderid: params.orderid,
                        path: order.customerpofilepath,
                        lang,
                        order,
                        setOrder,
                      }}
                    />
                  )}

                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Sales Channels:" : "銷售平台:",
                    value: 
                    lang === "en" ?
                    channellist.filter((channel) => channel.id === order.channels)[0].display
                    :
                    channellist.filter((channel) => channel.id === order.channels)[0].cdisplay,
                  }}
                />
                {order.channels === "sales" && (
                  <Singlefielddisplay
                    content={{ 
                      title: lang==="en" ? "Salesperson:" : "業務員:", 
                      value: order.sales 
                    }}
                  />
                )}
                <Singlefielddisplay
                  content={{ 
                    title: lang==="en" ? "Invoice Number:" : "發票編號:",
                    value: order.invoice
                  }}
                />
                {permission["orderdetail"]["invoice"][type] &&
                  order.orderid && ( //Only Sales and Admin can see the invoice
                    <Filehandle
                      fileinfo={{
                        type: "invoice",
                        orderid: params.orderid,
                        path: order.invoicefilepath,
                        lang,
                        order,
                        setOrder,
                      }}
                    />
                  )}
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "DN Number:" : "送貨單編號:",
                    value: order.dn
                  }}
                />
                {order.orderid && (
                  <Filehandle
                    fileinfo={{
                      type: "dn",
                      orderid: params.orderid,
                      path: order.dnfilepath,
                      lang,
                      order,
                      setOrder,
                    }}
                  />
                )}
              </Stack>
            </Paper>
            {permission["orderdetail"]["paymentstatus"][type] && (
              <Paper variant="outlined" className="paperstyle">
                <Stack justifyContent="flex-start" spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography paragraph variant="h2">
                      { lang==="en" ? "Payment Status" : "付款狀態" }
                    </Typography>
                    {permission["orderdetail"]["edit"][type] && 
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setDialogswitch({
                              ...dialogswitch,
                              paymentstatus: true,
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Stack>
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Payment Status:" : "付款狀態",
                      value: 
                      lang==="en" ? 
                      paystatuslist.filter((status) => status.id === order.paymentstatus)[0].display
                      :
                      paystatuslist.filter((status) => status.id === order.paymentstatus)[0].cdisplay,
                    }}
                  />
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Payment Term:" : "付款條款",
                      value: 
                      lang==="en" ? 
                      paymentlist.filter((status) => status.id === order.orderpayment)[0].display
                      :
                      paymentlist.filter((status) => status.id === order.orderpayment)[0].cdisplay,
                    }}
                  />
                  {
                    order.paymentstatus==="paid" ?
                    <Singlefielddisplay
                      content={{
                        title: lang==="en" ? "Payment Received:" : "收款日期",
                        value: order.paiddate
                          ? dateconvert(order.paiddate)
                          : "",
                      }}
                    />
                    :
                    <Singlefielddisplay
                      content={{
                        title: lang==="en" ? "Payment Due:" : "付款限期:",
                        value: order.paymentduedate
                          ? dateconvert(order.paymentduedate)
                          : "",
                      }}
                    />
                  }
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Total Amount:" : "總數:",
                      value: `HK$ ${order.total}`,
                    }}
                  />
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Payment Record:" : "付款紀錄:",
                      value: "",
                    }}
                  />
                  {order.orderid && (
                    <Filehandle
                      fileinfo={{
                        type: "payment",
                        orderid: params.orderid,
                        path: order.paymentfilepath,
                        lang,
                        order,
                        setOrder,
                      }}
                    />
                  )}
                </Stack>
              </Paper>
            )}
            <Paper variant="outlined" className="paperstyle">
              <Stack justifyContent="flex-start" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Typography paragraph variant="h2">
                    { lang==="en" ? "Customer Information" : "客戶資料" }
                  </Typography>
                  {permission["orderdetail"]["edit"][type] && 
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          setDialogswitch({
                            ...dialogswitch,
                            customerinformation: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Customer Type:" : "客戶類別:",
                    value: order.type
                  }}
                />
                {order.type === "Business" && (
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Company Name:" : "公司名稱:",
                      value: order.name
                    }}
                  />
                )}
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Contact Person:" : "聯絡人:",
                    value: order.contact
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Phone:" : "電話:",
                    value: order.phone
                  }}
                />
                <Singlefielddisplay
                  content={{ 
                    title: lang==="en" ? "Email:" : "電郵:",
                    value: order.email
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Address:" : "地址:",
                    value: order.address
                  }}
                />
                {order.address2 && (
                  <Singlefielddisplay
                    content={{
                      title: lang==="en" ? "Delivery Address:" : "送貨地址:",
                      value: order.address2,
                    }}
                  />
                )}
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Remarks:" : "備註:",
                    value: order.ctrremarks
                  }}
                />
              </Stack>
            </Paper>
            <Paper variant="outlined" className="paperstyle">
              <Stack justifyContent="flex-start" spacing={4}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Typography paragraph variant="h2">
                    { lang==="en" ? "Order Items:" : "選購貨品" }
                  </Typography>
                  {
                    permission["orderdetail"]["edit"][type] &&
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          setDialogswitch({
                            ...dialogswitch,
                            orderitems: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>
                <Stack
                  justifyContent="flex-start"
                  spacing={4}
                  divider={<Divider orientation="horizontal" flexItem />}
                >
                  {order.order
                    .filter(
                      (everyorder) =>
                        everyorder.itemid !== "ot-de" &&
                        everyorder.itemid !== "ot-ad"
                    )
                    .map((orderitem, index) => (
                      <Stack
                        justifyContent="flex-start"
                        spacing={1}
                        key={index}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          { lang==="en" ? `Item ${index + 1}` : `選購貨品 ${index + 1}` }
                        </Typography>
                        <Singlefielddisplay
                          content={{
                            title: lang==="en" ? "Brand:" : "品牌:",
                            value: itemlist.filter(
                              (item) => item.itemid === orderitem.itemid
                            )[0].brand,
                          }}
                        />
                        <Singlefielddisplay
                          content={{
                            title: lang==="en" ? "Item ID:" : "貨品編碼:",
                            value: orderitem.itemid,
                          }}
                        />
                        <Singlefielddisplay
                          content={{
                            title: lang==="en" ? "Item Name:" : "貨品名稱:",
                            value: itemlist.filter(
                              (item) => item.itemid === orderitem.itemid
                            )[0].name,
                          }}
                        />
                        <Singlefielddisplay
                          content={{
                            title: lang==="en" ? "Qty:" : "數量:",
                            value: orderitem.qty
                          }}
                        />
                        {permission["orderdetail"]["itemprice"][type] && (
                          <Singlefielddisplay
                            content={{
                              title: lang==="en" ? "Price:" : "價格:",
                              value: `HK$ ${orderitem.price}`,
                            }}
                          />
                        )}
                      </Stack>
                    ))}
                </Stack>
              </Stack>
            </Paper>
            <Paper variant="outlined" className="paperstyle">
              <Stack justifyContent="flex-start" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Typography paragraph variant="h2">
                    { lang==="en" ? "Samples & Marketing Materials" : "樣品及宣傳物資" }
                  </Typography>
                  {
                    permission["orderdetail"]["edit"][type] &&
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          setDialogswitch({
                            ...dialogswitch,
                            samples: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Samples:" : "樣品:",
                    value: order.samples
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Flyers:" : "宣傳單張:",
                    value: order.flyers
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Instruction Card:" : "食用咭:",
                    value: order.instructioncard,
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Promotion Card:" : "宣傳咭:",
                    value: order.promocard
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Posters:" : "海報:",
                    value: order.posters
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Display Props:" : "產品介紹牌:",
                    value: order.props
                  }}
                />
              </Stack>
            </Paper>
            {permission["orderdetail"]["additionalcharges"][type] && (
              <Paper variant="outlined" className="paperstyle">
                <Stack justifyContent="flex-start" spacing={4}>
                  <Typography paragraph variant="h2">
                    { lang==="en" ? "Additional Charges" : "附加費用" }
                  </Typography>
                  <Stack
                    justifyContent="flex-start"
                    spacing={4}
                    divider={<Divider orientation="horizontal" flexItem />}
                  >
                    {order.order
                      .filter(
                        (everyorder) =>
                          everyorder.itemid === "ot-de" ||
                          everyorder.itemid === "ot-ad"
                      )
                      .map((orderitem, index) => (
                        <Stack
                          justifyContent="flex-start"
                          spacing={1}
                          key={index}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            { lang==="en" ? `Service ${index + 1}` : `服務 ${index + 1}` }
                          </Typography>
                          <Singlefielddisplay
                            content={{
                              title: lang==="en" ? "Service:" : "服務:",
                              value: itemlist.filter(
                                (item) => item.itemid === orderitem.itemid
                              )[0].name,
                            }}
                          />
                          <Singlefielddisplay
                            content={{
                              title: lang==="en" ? "Description:" : "內容:",
                              value: orderitem.description,
                            }}
                          />
                          <Singlefielddisplay
                            content={{
                              title: lang==="en" ? "Price:" : "價格:",
                              value: `HK$ ${orderitem.price}`,
                            }}
                          />
                        </Stack>
                      ))}
                  </Stack>
                </Stack>
              </Paper>
            )}
            <Paper variant="outlined" className="paperstyle">
              <Stack justifyContent="flex-start" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Typography paragraph variant="h2">
                    { lang==="en" ? "Delivery Status" : "送貨狀態" }
                  </Typography>
                  {
                    permission["orderdetail"]["edit"][type] &&
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          setDialogswitch({
                            ...dialogswitch,
                            deliverystatus: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Delivery Method:" : "送貨方法:",
                    value: order.deliverymethod
                      ? (
                          lang === "en" ?
                          deliverylist.filter((delivery) => delivery.id === order.deliverymethod)[0].display
                          :
                          deliverylist.filter((delivery) => delivery.id === order.deliverymethod)[0].cdisplay
                        )
                      : "",
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Delivery Date:" : "送貨日期:",
                    value: order.deliverydate
                      ? dateconvert(order.deliverydate)
                      : "",
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Remarks:" : "備註:",
                    value: order.remarks
                  }}
                />
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Courier Tracking Document:" : "送貨速遞單:"
                  }}
                />
                {order.orderid && (
                  <Filehandle
                    fileinfo={{
                      type: "courier",
                      orderid: params.orderid,
                      path: order.courierfilepath,
                      lang,
                      order,
                      setOrder,
                    }}
                  />
                )}
                <Singlefielddisplay
                  content={{
                    title: lang==="en" ? "Other files:" : "其他檔案:"
                  }}
                />
                {order.orderid && otherfilearray ?
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                  {
                    otherfilearray.map((filepath, index, array)=>
                      <Stack
                        key={index}
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        divider={<Divider orientation="horizontal" flexItem />}
                      >
                        <Filehandle
                          fileinfo={{
                            type: `other${index}`,
                            orderid: params.orderid,
                            path: filepath,
                            lang,
                            otherfilearray,
                            setOtherfilearray,
                            order,
                            setOrder,
                          }}
                        />
                        {
                          (array.length===index+1) &&
                            <Filehandle
                              fileinfo={{
                                type: `other${index+1}`,
                                orderid: params.orderid,
                                path: "",
                                lang,
                                otherfilearray,
                                setOtherfilearray,
                                order,
                                setOrder,
                              }}
                            />
                        }
                      </Stack>
                    )
                  }
                  </Stack>
                :
                <Filehandle
                  fileinfo={{
                    type: "other0",
                    orderid: params.orderid,
                    path: "",
                    lang,
                    setOtherfilearray,
                    order,
                    setOrder,
                  }}
                />
                }
              </Stack>
            </Paper>
            <Paper elevation={0} className="paperstyle">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ py: "16px" }}
              >
                <Button
                  variant="contained"
                  onClick={() => navigate("/orderlist/")}
                  disableElevation
                >
                  { lang==="en" ? "Back" : "返回" }
                </Button>
                {permission["orderdetail"]["delivereddialog"][type] && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setDialogswitch({
                        ...dialogswitch,
                        delivereddialog: true,
                      });
                    }}
                    disableElevation
                  >
                    { lang==="en" ? "DELIVERED" : "已安排出貨" }
                  </Button>
                )}
                {permission["orderdetail"]["edit"][type] && (
                  <Button
                    variant="outlined"
                    sx={{color: "#739c70"}}
                    onClick={() => {
                      setDialogswitch({
                        ...dialogswitch,
                        deleteorder: true,
                      });
                    }}
                    disableElevation
                  >
                    { lang==="en" ? "DELETE ORDER" : "刪除訂單" }
                  </Button>
                )}
              </Stack>
            </Paper>
            {
              //Order Status Dialog Box
            }
            <Dialogbox
              props={{
                section: "orderstatus",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                itemlist,
                lang,
              }}
            />

            {
              //Payment Status Dialog Box
            }
            <Dialogbox
              props={{
                section: "paymentstatus",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                lang,
              }}
            />

            {
              //Samples Dialog Box
            }
            <Dialogbox
              props={{
                section: "samples",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                lang,
              }}
            />

            {
              //Delivery Status Dialog Box
            }
            <Dialogbox
              props={{
                section: "deliverystatus",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                lang,
              }}
            />

            {
              //Customer information Dialog Box
            }
            <Dialogbox
              props={{
                section: "customerinformation",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                lang,
              }}
            />

            {
              //Item Orders Dialog Box
              sorteditemlist &&
              <Dialogbox
                props={{
                  section: "orderitems",
                  dialogswitch,
                  setDialogswitch,
                  order,
                  setOrder,
                  itemlist: sorteditemlist,
                  lang,
                }}
              />
            }

            {
              //Warehouse Dialog Box
            }
            <Dialogbox
              props={{
                section: "delivereddialog",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                itemlist,
                lang,
              }}
            />

            {
              //Delete Order Dialog Box
            }
            <Dialogbox
              props={{
                section: "deleteorder",
                dialogswitch,
                setDialogswitch,
                order,
                setOrder,
                itemlist,
                lang,
              }}
            />
          </React.Fragment>
        ) : (
          //When fetching the data
          <Typography paragraph variant="h2" align="center" sx={{ my: 4 }}>
            <CircularProgress />
          </Typography>
        )}
        {
          //Go to TOP
        }
        <Zoom in={trigger}>
          <Box
            onClick={tophandleClick}
            role="presentation"
            sx={{ position: "fixed", bottom: 24, right: 24 }}
          >
            <Fab
              color="secondary"
              size="medium"
              aria-label="scroll back to top"
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
      </Stack>
    </Box>
  );
}
