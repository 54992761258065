import React, { useState, useEffect } from "react";
import './Global.css'
import { Box, Typography, Button, TextField, Stack } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { signin } from "../firebase/authhandling";

export default function Login({loadingstatus}) {
  const {loading, setLoading} = loadingstatus;

  //Store the credentials data
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    error: "",
  });

  //Login flag hook
  const [login, setLogin] = useState(false);
  useEffect(() => {
    if (login) {
      //console.log(`${credentials.email} and ${credentials.password}`)
      //console.log("Login Clicked");

      if (credentials.email !== "" && credentials.password !== "") {
        //Make sure no credential info is missing
        signin(credentials.email, credentials.password) //Call the function signin()
          .then((result) => {
            //console.log(result);
          })
          .catch((error) => {
            //Failure to login
            console.log(error);
            setCredentials({ ...credentials, error }); //Capture the error message
            setLoading(false);
          });
      } else {
        //if something in the form is missing
        let errormsg = "";
        if (credentials.email === "") {
          errormsg = "Email cannot be empty! ";
        }
        if (credentials.password === "") {
          errormsg = errormsg + "Password cannot be empty! ";
        }
        setCredentials({ ...credentials, error: errormsg }); //Capture the error message
        setLoading(false);
      }
    }
    return () => {
      setLogin(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  //Fired by the Login button clicked
  const handleLogIn = (event) => {
    setLoading(true);
    setLogin(true);
  };

  //Store the input value into the hook credentials
  const handleChange = (prop) => (event) => {
    setCredentials({ ...credentials, [prop]: event.target.value, error: "" }); //For the email and password and reset the error message once typed something
  };

  return (
    <Box className="logincontainer">
      <Stack alignItems="center" spacing={4}>
        <Typography
          variant="h1"
          align="center"
          sx={{ mt: "40px", mb: "16px" }}
        >
          Go 2 Health Group Limited
        </Typography>
        <Typography variant="body1" align="center">
          Internal System Login
        </Typography>
        <Typography variant="body1" align="center">
          員工系統登入
        </Typography>
        {
          //Display the captured error message if it exists
          credentials.error !== "" && (
            <Typography
              id="errormessage"
              variant="body1"
              align="center"
              sx={{ color: "#FF0000" }}
            >
              {credentials.error}
            </Typography>
          )
        }
        <TextField
          required
          size="small"
          id="email"
          label="Email 電郵"
          placeholder="Email 電郵"
          variant="outlined"
          value={credentials.email}
          onChange={handleChange("email")}
          sx={{ width: "30ch" }}
        />
        <TextField
          required
          size="small"
          type="password"
          id="password"
          label="Password 密碼"
          placeholder="Password 密碼"
          variant="outlined"
          value={credentials.password}
          onChange={handleChange("password")}
          sx={{ width: "30ch" }}
        />
        <Button
          variant="contained"
          onClick={handleLogIn}
          disabled={login ? true : false}
          disableElevation
          sx={{ width: "30ch" }}
        >
          LOGIN 登入
        </Button>
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
